import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'

import Content, { HTMLContent } from '../components/Content'
import {
  FluxibleSocialMediaBlock,
} from '../components/FluxibleIcon'
import { getImage } from '../util'

const SpeakerBio = ({ speaker }) => {
  const bio = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(speaker.bio)
    .toString()

  const SpeakerContent = HTMLContent || Content
  const socialMediaType = {
    twitter: 'fab fa-twitter-square',
    instagram: 'fab fa-instagram-square',
    linkedin: 'fab fa-linkedin',
    website: 'fa fa-home',
  }

  const getSocialMedia = () => {
    const socialMedia = speaker.socialMedia

    if (!socialMedia) return null

    const linksList = socialMedia.map(medium => {
      return (
        <FluxibleSocialMediaBlock
          key={v4()}
          icon={socialMediaType[medium.type]}
          text={medium.value}
          link={medium.value}
        />
      )
    })

    return <div className="social_media_section">{linksList}</div>
  }

  return (
    <div className="event_speaker_content">
      <h5 className="title event_speaker_title">About {speaker.name}</h5>
      <h6 className="subtitle is-size-6 is-italic">{speaker.jobPosition}</h6>
      { getImage(speaker.photo, speaker.name, "event_speaker_photo") }
      <SpeakerContent content={bio} />
      {getSocialMedia(speaker.socialMedia, speaker.website)}
    </div>
  )
}

SpeakerBio.propTypes = {
  speaker: PropTypes.object,
}

export default SpeakerBio
