import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'
import moment from 'moment-timezone'

import Content, { HTMLContent } from '../components/Content'
import { FluxibleIconBlock } from '../components/FluxibleIcon'
import Layout from '../components/layout'
import SpeakerBio from '../components/SpeakerBio'
import Navbar from '../components/Navbar'

import { formatDate, formatDuration, TZ } from '../util/events'

const EventTemplate = ({
  content,
  contentComponent,
  name,
  helmet,
  affiliatedPresenter,
  startDate,
  endDate,
  location,
  eventType,
  speakers,
}) => {
  const EventContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="content">
        {location &&
          location.name && (
            <FluxibleIconBlock
              icon="building"
              text={location.name}
              link={location.fields.slug}
              size="sm"
            />
          )}
        <div className="fluxible_date_header event_date_header">
          <span className="event_time">
            {formatDuration(startDate, endDate)} {" "} {moment.tz(TZ).zoneAbbr()}
          </span>{' '}
          {formatDate(startDate)}
        </div>
        {eventType && eventType.displayName && (
            <div className="fluxible_event_type">{eventType.displayName}</div>
            )}

        <h1 className="is-size-2 has-text-weight-bold is-bold-light is-size-3-tablet is-size-4-mobile event_title_header">
          {name}
        </h1>

        {speakers && (
          <div className="is-italic">
            {speakers.map(s => s.name).join(', ')}
          </div>
        )}

        {affiliatedPresenter &&
          affiliatedPresenter !== '' && (
            <p className="is-italic">Presented by: {affiliatedPresenter}</p>
          )}

        <EventContent content={content} className="event_content" />

        {speakers && (
          <>
            {Object.keys(speakers).map(key => {
              const s = speakers[key]
              if (!s) return null
              return <SpeakerBio speaker={s} key={s.name + `speaker`} />
            })}
          </>
        )}
      </div>
    </section>
  )
}

EventTemplate.propTypes = {
  name: PropTypes.string,
  helmet: PropTypes.object,
  description: PropTypes.string,
  affiliatedPresenter: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.object,
  eventType: PropTypes.object,
  speakers: PropTypes.array,
}

class Event extends React.Component {
  componentDidMount() {
    window.sessionStorage.setItem('eventLoaded', 'true')
  }

  render() {
    const { event } = this.props.data

    const description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(event.description)
      .toString()

    return (
      <Layout>
        <section className="section">
          <Navbar showBackArrow={true} />
          <EventTemplate
            helmet={<Helmet title={`${event.name} | Event`} />}
            content={description}
            contentComponent={HTMLContent}
            name={event.name}
            affiliatedPresenter={event.affiliatedPresenter}
            startDate={event.startDate}
            endDate={event.endDate}
            location={event.location}
            eventType={event.eventType}
            speakers={event.speakers}
          />
        </section>
      </Layout>
    )
  }
}

Event.propTypes = {
  data: PropTypes.shape({
    fluxibleCmsEvents: PropTypes.object,
  }),
}

export default Event

export const pageQuery = graphql`
  query CmsEventByID($id: String!) {
    event: fluxibleEvents(id: { eq: $id }) {
      id
      name
      description
      affiliatedPresenter
      startDate
      endDate
      eventType {
        name
        displayName
      }
      location {
        name
        fields {
          slug
        }
      }
      speakers {
        name
        jobPosition
        bio
        socialMedia {
          type
          value
        }
        photo {
          name
          altText
          contentType
        }
      }
    }
  }
`
